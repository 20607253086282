import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Teams from "../components/teams"
import Faq from "../components/faq"
import Svg from "../components/svg"
import eelbeyglu from "../assets/img/allteam.png"


const About = () => (
    <Layout>
        <SEO title="About Us" description="We are building actions, creative ideas, functional designs and target-oriented strategies that will help you to reach your clients." />
        <div className="about container" >
            <div className="about-top">
                <h1>Analysis. Acquisition.<br /> Conversion.</h1>
                <h3>ABOUT US</h3>
                <p>Three words. One answer: Flatart. We are building actions, creative ideas, functional designs and target-oriented strategies that will help you to reach your clients in a world of 8 billion people.</p>
                <div className="about-top_row container row">
                    <div className="col-md-3">
                        <span>$10M+</span>
                        <p>Managed Advertising Spends</p>
                    </div>
                    <div className="col-md-3">
                        <span>123+</span>
                        <p>Brands Cooperated</p>
                    </div>
                    <div className="col-md-3">
                        <span>%65+</span>
                        <p>Long Term Collaboration Rate</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="">
            {/* <img alt="alt text" src={lion}></img> */}
            <Svg />
        </div>
        <div className="about container">
            <div className="methodology">
                <h2 style={{marginTop:0}}>Our Methodology to Grow Your Brand</h2>
                <p className="med-p">We analyse and drive insights from your brand’s data, and use them in marketing activities. By enabling you to interact with your customers, we are building ways to transform your brand. We create reports of the entire journey to keep you up to date.</p>
                <div className="circles">
                    <div className="circle">
                        <div className="circle-content">
                            <h3>Analysis</h3>
                            <p>
                                - Analysis Consulting<br />
                                - Brand Audit<br />
                                - UI/UX Audits<br />
                                - SEO/SEM Audits
                        </p>
                        </div>
                    </div>
                    <div className="circle">
                        <div className="circle-content">
                            <h3>Acquisition</h3>
                            <p>
                                - SEO/SEM<br />
                                - Digital Ads<br />
                                - Social Media
                        </p>
                        </div>
                    </div>
                    <div className="circle">
                        <div className="circle-content">
                            <h3>Transformation</h3>
                            <p>
                                - UI/UX Design <br />
                                - Lead Generation<br />
                                - Website Enhancements<br />
                                - CRO
                        </p>
                        </div>
                    </div>
                    <div className="circle">
                        <div className="circle-content">
                            <h3>Growth</h3>
                            <p>
                                - Creating a Brand-Specific Strategic Plan<br />
                                - Getting High Quality Traffic<br />
                                - Improving the Website and Design<br />
                                - Increasing Conversion Rates<br />
                                - Increasing Brand Awareness and Achieving Profit
                        </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="team" id="ekip">
                <div className="team-content">
                    <h2>Team members <br /> who grow brands.</h2>
                    <p>Talented. Equipped. Professional. Innovator. <br />Dynamic. Solution-oriented. The Flatart Team.</p>
                    <br />
                    <img style={{width: '35%'}} alt="Alaattin Emre Elbeyoğlu" src={eelbeyglu}></img>
                </div>
                <Teams />
            </div>
            <div className="hiring" style={{marginTop: -80}} id="ik">
                <h2>Open positions</h2>
                <p style={{ textAlign: 'center' }}>If there are any suitable positions for you, <br/> go ahead, apply to join us.</p>
                <Faq />
            </div>


        </div>
    </Layout >
)

export default About
