import React from "react"
import { Link } from "gatsby"



const Faq = () => (

    // <input type="text" name="title" value={this.state.title} onChange={this.handleChange.bind(this)} />

    <div className="hiring-tabs">
        <input type="checkbox" name="dropdown" id="tab1" />
        <input type="checkbox" name="dropdown" id="tab2" />
        <input type="checkbox" name="dropdown" id="tab3" />
        <input type="checkbox" name="dropdown" id="tab4" />
        <input type="checkbox" name="dropdown" id="tab5" />

        <div className="tab" id="tab1sec">
            <label htmlFor="tab1"> </label>
            <h3>Front-end Developer</h3>
            <p>Bringing the websites and apps designed by the web designers to life by coding them in HTML, CSS and JavaScript. </p>
            <p><a href="https://forms.flatart.com.tr/index.php?r=app%2Fform&id=3">Apply Now.</a></p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab2sec">
            <label htmlFor="tab2"> </label>
            <h3>Digital Marketing Specialist</h3>
            <p>Creating digital marketing strategies through online tools such as e-mail, social media networks, e-mail, search engines, and manages advertising campaigns for brand targets.</p>
            <p><a href="https://forms.flatart.com.tr/index.php?r=app%2Fform&id=3">Apply Now.</a></p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab3sec">
            <label htmlFor="tab3"> </label>
            <h3>Social Media Manager</h3>
            <p>Developing tailored social media strategies for brands, and strategising for increased interaction with the target audience, using the most appropriate communication language.</p>
            <p><a href="https://forms.flatart.com.tr/index.php?r=app%2Fform&id=3">Apply Now.</a></p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab4sec">
            <label htmlFor="tab4"> </label>
            <h3>Brand Manager</h3>
            <p>Identifying the digital needs of the brands it represents, developing projects in line with its growth plan, and ensuring the necessary improvements by following the branding process.</p>
            <p><a href="https://forms.flatart.com.tr/index.php?r=app%2Fform&id=3">Apply Now.</a></p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab5sec">
            <label htmlFor="tab5"> </label>
            <h3>Graphic Designer</h3>
            <p>Visualising the message of the brand. By working in partnership with other departments, creating designs suitable for the general identity of the brand.</p>
            <p><a href="https://forms.flatart.com.tr/index.php?r=app%2Fform&id=3">Apply Now.</a></p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
    </div>
)

export default Faq
